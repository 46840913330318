import {Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import * as moment from 'moment';
import 'moment/locale/nl-be';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GROEPEN} from '../../utils/model.utils';

@Component({
  selector: 'app-repetitie-form',
  templateUrl: './repetitie-form.component.html',
  styleUrls: ['./repetitie-form.component.scss'],
})
export class RepetitieFormComponent {

  groepen = GROEPEN;

  form = this.fb.group({
    groep: new FormControl('', Validators.required),
    datum: new FormControl('', Validators.required),
    herhalingen: new FormControl(10, Validators.required),
    limiet: new FormControl(0, Validators.required)
  });

  constructor(private fb: FormBuilder, private sandbox: FirebaseSandbox) {
  }

  save() {
  }

  genereer() {
    // todo unsubscribe
    of(...Array.from(Array(this.form.value.herhalingen).keys())).pipe(
      map(i => ({
        groep: this.form.value.groep,
        datetime: moment(this.form.value.datum)
          .locale('nl-be')
          .add(i, 'weeks')
          .format(),
        aanwezig: [],
        afwezig: [],
        coordinators: [],
        dirigent: null,
        limit: this.form.value.limiet,
        afgesloten: false
      })),
    ).subscribe(repetitie => this.sandbox.addRepetitie(repetitie as any));
  }
}
