import {Component} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {AanwezigheidRol} from '../../models/aanwezigheid-type';
import {AANWEZIGHEID_TYPES} from '../../utils/model.utils';
import {UserSandbox} from "../../sandbox/user.sandbox";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-aanwezigheid-popover',
  templateUrl: './aanwezigheid-popover.component.html',
  styleUrls: ['./aanwezigheid-popover.component.scss'],
})
export class AanwezigheidPopoverComponent {

  aanwezigheidTypes = AANWEZIGHEID_TYPES;

  $roles = this.userSandbox.$profile.pipe(
    map(profile => profile.roles)
  );

  constructor(private popover: PopoverController, private userSandbox: UserSandbox) {
  }

  select(type: AanwezigheidRol) {
    this.popover.dismiss(type);
  }

  reset() {
    this.popover.dismiss(null);
  }
}
