import {Component, Input} from '@angular/core';
import {Activiteit} from '../../models/activiteit';
import {AanwezigheidPopoverComponent} from '../aanwezigheid-popover/aanwezigheid-popover.component';
import {AanwezigheidRol} from '../../models/aanwezigheid-type';
import {PopoverController} from '@ionic/angular';
import firebase from 'firebase';
import {FirebaseSandbox} from "../../sandbox/firebase.sandbox";
import User = firebase.User;

@Component({
  selector: 'app-activiteit-item',
  templateUrl: './activiteit-item.component.html',
  styleUrls: ['./activiteit-item.component.scss'],
})
export class ActiviteitItemComponent {

  @Input() activiteit: Activiteit;
  @Input() small = false;
  @Input() user: User;

  constructor(public popoverController: PopoverController, private sandbox: FirebaseSandbox) {
  }

  async openAanwezigheidPopover(activiteit: Activiteit, ev: any) {
    const popover = await this.popoverController.create({
      component: AanwezigheidPopoverComponent,
      event: ev,
      translucent: true
    });
    await popover.present();

    const {data} = await popover.onDidDismiss();
    this.updateAanwezigheid(data);
  }

  getAanwezigheid() {
    return this.activiteit.aanwezig.find(a => a === this.user.uid) ? AanwezigheidRol.aanwezig :
      this.activiteit.afwezig.find(a => a === this.user.uid) ? AanwezigheidRol.afwezig :
        this.activiteit.coordinators.find(a => a === this.user.uid) ? AanwezigheidRol.coordinator :
          this.activiteit.dirigent === this.user.uid ? AanwezigheidRol.dirigent : null;
  }

  updateAanwezigheid(data: any) {
    this.sandbox.setAanwezigheidActiviteit(this.activiteit, this.user.uid, data);
  }
}
