import {Component, OnInit} from '@angular/core';
import {GROEPEN} from '../../utils/model.utils';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {Activiteit} from '../../models/activiteit';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent {

  file: File;

  form = this.fb.group({
   // id: new FormControl(),
    name: new FormControl('', Validators.required),
  });

  constructor(private fb: FormBuilder, private sandbox: FirebaseSandbox) {
  }

  opslaan() {
    console.log(this.file);
    console.log(this.form);
   // this.sandbox.addDocument(this.form.value, this.file);
  }

  upload(event) {
    this.file = event.target.files[0];
  }
}
