import {Role} from './role';

export interface AanwezigheidType {
  value: AanwezigheidRol;
  name: string;
  color: string;
  chipColor: string;
  role: Role;
}

export enum AanwezigheidRol {
  aanwezig = 'aanwezig',
  afwezig = 'afwezig',
  dirigent = 'dirigent',
  coordinator = 'coordinator'
}
