import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AanwezighedenRoutingModule} from './aanwezigheden-routing.module';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {AanwezighedenListComponent} from './aanwezigheden-list/aanwezigheden-list.component';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    AanwezighedenListComponent
  ],
  imports: [
    CommonModule,
    AanwezighedenRoutingModule,
    Ng2SearchPipeModule,
    IonicModule,
    SharedModule
  ]
})
export class AanwezighedenModule {
}
