import {Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {Album} from '../../models/album';

@Component({
  selector: 'app-photos-form',
  templateUrl: './photos-form.component.html',
  styleUrls: ['./photos-form.component.scss'],
})
export class PhotosFormComponent {

  form = this.fb.group({
    id: new FormControl(),
    date: new FormControl(null, Validators.required),
    name: new FormControl('', Validators.required),
    url: new FormControl('', Validators.required),
    image: new FormControl('', Validators.required),
    showOnWebsite: new FormControl(false, Validators.required)
  });

  constructor(private fb: FormBuilder, private sandbox: FirebaseSandbox) {
  }

  opslaan() {
    this.sandbox.addAlbum(this.form.value as Album);
  }
}
