import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {UserSandbox} from "../../sandbox/user.sandbox";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-home.page',
  templateUrl: './home.page.component.html',
  styleUrls: ['./home.page.component.scss'],
})
export class HomePageComponent {

  $documents = this.sandbox.$documents.pipe(take(3));

  $upcomingEvents = this.sandbox.$upcomingActiviteiten;

  $profiel = this.userSandbox.$profile;

  $user = this.userSandbox.$user;

  constructor(private sandbox: FirebaseSandbox, private userSandbox: UserSandbox) {

  }

  toggleMenu() {
    const splitPane = document.querySelector('ion-split-pane');
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;
    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane.when === when;
      splitPane.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open
      const menu = splitPane.querySelector('ion-menu');
      return menu.open();
    }
  }
}
