import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {Profiel} from '../../models/profiel';
import {Repetitie} from '../../models/repetitie';
import {GROEPEN} from '../../utils/model.utils';

@Component({
  selector: 'app-aanwezigheden-list',
  templateUrl: './aanwezigheden-list.component.html',
  styleUrls: ['./aanwezigheden-list.component.scss'],
})
export class AanwezighedenListComponent {

  $upcomingRepetities = this.sandbox.$upcomingRepetities;
  $profielen = this.sandbox.$profielen;

  term: string;
  selectedGroep: string = null;

  groepen = GROEPEN;

  constructor(private sandbox: FirebaseSandbox) {
  }

  isAanwezig(repetitie: Repetitie, profiel: Profiel) {
    return repetitie.aanwezig.includes(profiel.id)
      || repetitie.dirigent === profiel.id
        || repetitie.coordinators.includes(profiel.id);
  }

  generateName(repetitie: any, profiel: Profiel) {
    return repetitie.dirigent === profiel.id
        ? `${profiel.familienaam.toUpperCase()} ${profiel.voornaam} (Dirigent)`
        : repetitie.coordinators.includes(profiel.id)
            ? `${profiel.familienaam.toUpperCase()} ${profiel.voornaam} (Coördinator)`
            : `${profiel.familienaam.toUpperCase()} ${profiel.voornaam}`;
  }

  search(event: any) {
    this.term = event.detail.value;
  }
}
