import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import localeBE from '@angular/common/locales/nl-BE';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/analytics';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginModule} from './login/login.module';
import {HomeModule} from './home/home.module';
import {registerLocaleData} from '@angular/common';
import {FirebaseSandbox} from './sandbox/firebase.sandbox';
import {RepetitiesModule} from './repetities/repetities.module';
import {DocumentenModule} from './documenten/documenten.module';
import {ProfielModule} from './profiel/profiel.module';
import {UserSandbox} from './sandbox/user.sandbox';
import {AanwezighedenModule} from './aanwezigheden/aanwezigheden.module';
import {PreviewAnyFile} from '@ionic-native/preview-any-file/ngx';
import {ActiviteitenModule} from './activiteiten/activiteiten.module';
import {PhotosModule} from './photos/photos.module';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';

registerLocaleData(localeBE);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    LoginModule,
    HomeModule,
    FormsModule,
    RepetitiesModule,
    DocumentenModule,
    ProfielModule,
    AanwezighedenModule,
    ActiviteitenModule,
    PhotosModule
  ],
  providers: [
    ScreenTrackingService,
    FirebaseSandbox,
    UserSandbox,
    PreviewAnyFile,
    LocalNotifications,
    {provide: LOCALE_ID, useValue: 'nl-BE'},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
