import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AanwezighedenListComponent} from './aanwezigheden-list/aanwezigheden-list.component';

const routes: Routes = [
  {
    path: '',
    component: AanwezighedenListComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AanwezighedenRoutingModule {
}
