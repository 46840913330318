import {Component, Input} from '@angular/core';
import {AANWEZIGHEID_TYPES} from '../../utils/model.utils';
import {AanwezigheidRol, AanwezigheidType} from '../../models/aanwezigheid-type';

@Component({
  selector: 'app-aanwezigheid-bullet',
  templateUrl: './aanwezigheid-bullet.component.html',
  styleUrls: ['./aanwezigheid-bullet.component.scss'],
})
export class AanwezigheidBulletComponent {

  selectedType: AanwezigheidType;
  aanwezigheidTypes = AANWEZIGHEID_TYPES;

  @Input() set aanwezigheidType(value: AanwezigheidRol) {
    this.selectedType = AANWEZIGHEID_TYPES.find(e => e.value === value);
  }
}
