import {Component, Input} from '@angular/core';
import {Bestand} from '../../models/bestand';
import {PreviewAnyFile} from '@ionic-native/preview-any-file/ngx';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent {

  @Input() document: Bestand;
  @Input() small = false;

  constructor(private previewAnyFile: PreviewAnyFile, private platform: Platform) {
  }

  async bekijken(document: Bestand) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return this.previewAnyFile.preview(document.url);
    } else {
      window.open(document.url, '_blank');
    }
  }
}
