import {Component} from '@angular/core';
import {GROEPEN} from '../../utils/model.utils';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {Activiteit} from '../../models/activiteit';

@Component({
  selector: 'app-activiteit-form',
  templateUrl: './activiteit-form.component.html',
  styleUrls: ['./activiteit-form.component.scss'],
})
export class ActiviteitFormComponent {

  groepen = GROEPEN;

  form = this.fb.group({
    id: new FormControl(),
    groepen: new FormControl(null, Validators.required),
    naam: new FormControl('', Validators.required),
    locatie: new FormControl('', Validators.required),
    gemeente: new FormControl('Knokke-Heist', Validators.required),
    vertrek: new FormControl(null, Validators.required),
    verzamelen: new FormControl(null),
    zichtbaar: new FormControl(true, Validators.required),
    aanwezig: new FormControl([]),
    afwezig: new FormControl([]),
    coordinators: new FormControl([]),
    dirigent: new FormControl(null),
    afgesloten: new FormControl(false),
    limiet: new FormControl(0, Validators.required)
  });

  constructor(private fb: FormBuilder, private sandbox: FirebaseSandbox) {
  }

  opslaan() {
    this.sandbox.addActiviteit(this.form.value as Activiteit);
  }
}
