import {Component, Input} from '@angular/core';
import {Profiel} from '../../models/profiel';

@Component({
  selector: 'app-profiel-item',
  templateUrl: './profiel-item.component.html',
  styleUrls: ['./profiel-item.component.scss'],
})
export class ProfielItemComponent {

  @Input() profiel: Profiel;
}
