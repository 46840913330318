import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RepetitiesRoutingModule} from './repetities-routing.module';
import {RepetitieListComponent} from './repetitie-list/repetitie-list.component';
import {IonicModule} from '@ionic/angular';
import {RepetitieFormComponent} from './repetitie-form/repetitie-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    RepetitieListComponent,
    RepetitieFormComponent
  ],
  imports: [
    CommonModule,
    RepetitiesRoutingModule,
    IonicModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class RepetitiesModule {
}
