import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {map} from 'rxjs/operators';
import {Repetitie} from '../../models/repetitie';
import {UserSandbox} from '../../sandbox/user.sandbox';
import {GROEPEN} from '../../utils/model.utils';

@Component({
    selector: 'app-repetitie-list',
    templateUrl: './repetitie-list.component.html',
    styleUrls: ['./repetitie-list.component.scss'],
})
export class RepetitieListComponent {

    $user = this.userSandbox.$user;

    groepen = GROEPEN;

    $upcomingRepetitiesDrumband = this.sandbox.$upcomingRepetities.pipe(
        map((repetities: Repetitie[]) => [...repetities].filter(e => e.groep === 'DRUMBAND'))
    );

    $upcomingRepetitiesHarmonie = this.sandbox.$upcomingRepetities.pipe(
        map((repetities: Repetitie[]) => [...repetities].filter(e => e.groep === 'HARMONIE'))
    );

    $upcomingRepetitiesJeugdorkest = this.sandbox.$upcomingRepetities.pipe(
        map((repetities: Repetitie[]) => [...repetities].filter(e => e.groep === 'JEUGDORKEST'))
    );

    selectedGroep: string = this.groepen[0].value;

    $isAdmin = this.userSandbox.$isAdmin;

    constructor(private sandbox: FirebaseSandbox, private userSandbox: UserSandbox) {
    }
}
