import {Component, Input} from '@angular/core';
import {Repetitie} from '../../models/repetitie';
import {AanwezigheidPopoverComponent} from '../aanwezigheid-popover/aanwezigheid-popover.component';
import {AanwezigheidRol} from '../../models/aanwezigheid-type';
import {PopoverController} from '@ionic/angular';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';

@Component({
  selector: 'app-repetitie-item',
  templateUrl: './repetitie-item.component.html',
  styleUrls: ['./repetitie-item.component.scss'],
})
export class RepetitieItemComponent {

  @Input() repetitie: Repetitie;
  @Input() user: any;
  @Input() groep: string;
  @Input() small = false;

  constructor(private popoverController: PopoverController, private sandbox: FirebaseSandbox) {
  }

  async openAanwezigheidPopover(repetitie: Repetitie, userId: string, ev: any) {
    const popover = await this.popoverController.create({
      component: AanwezigheidPopoverComponent,
      event: ev,
      translucent: true
    });
    await popover.present();

    const {data} = await popover.onDidDismiss();
    this.sandbox.setAanwezigheidRepetitie(repetitie, userId, data);
  }

  getAanwezigheid(repetitie: Repetitie, uid: string) {
    return repetitie.aanwezig.find(a => a === uid) ? AanwezigheidRol.aanwezig :
      repetitie.afwezig.find(a => a === uid) ? AanwezigheidRol.afwezig :
        repetitie.coordinators.find(a => a === uid) ? AanwezigheidRol.coordinator :
          repetitie.dirigent === uid ? AanwezigheidRol.dirigent : null;
  }
}
