import {AanwezigheidRol, AanwezigheidType} from '../models/aanwezigheid-type';
import {Role} from "../models/role";
import {RoleType} from "../models/role-type";

export const GROEPEN = [
  {
    value: 'DRUMBAND',
    name: 'Drumband'
  }, {
    value: 'HARMONIE',
    name: 'Harmonie'
  }, {
    value: 'JEUGDORKEST',
    name: 'Jeugdorkest'
  },
];

export const AANWEZIGHEID_TYPES: AanwezigheidType[] = [
  {
    value: AanwezigheidRol.aanwezig,
    name: 'Aanwezig',
    color: 'green',
    chipColor: 'success',
    role: Role.lid
  }, {
    value: AanwezigheidRol.afwezig,
    name: 'Afwezig',
    color: 'red',
    chipColor: 'danger',
    role: Role.lid
  }, {
    value: AanwezigheidRol.dirigent,
    name: 'Dirigent',
    color: 'white',
    chipColor: 'tertiary',
    role: Role.dirigent
  }, {
    value: AanwezigheidRol.coordinator,
    name: 'Coördinator',
    color: 'white',
    chipColor: 'secondary',
    role: Role.coordinator
  }
];

export const ROLE_TYPES: RoleType[] = [
  {
    value: Role.lid,
    name: 'Lid',
  }, {
    value: Role.dirigent,
    name: 'Dirigent'
  }, {
    value: Role.bestuur,
    name: 'Bestuur'
  }, {
    value: Role.coordinator,
    name: 'Coördinator'
  }, {
    value: Role.admin,
    name: 'Administrator'
  }
];
