import {Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {ActivatedRoute, Router} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Profiel} from '../../models/profiel';
import {ROLE_TYPES} from '../../utils/model.utils';
import {UserSandbox} from '../../sandbox/user.sandbox';
import {Role} from '../../models/role';

@Component({
  selector: 'app-profiel-form',
  templateUrl: './profiel-form.component.html',
  styleUrls: ['./profiel-form.component.scss'],
})
export class ProfielFormComponent {

  new = false;

  form = this.fb.group({
    id: new FormControl(null, Validators.required),
    voornaam: new FormControl('', Validators.required),
    familienaam: new FormControl('', Validators.required),
    lidDrumband: new FormControl(false),
    lidJeugdorkest: new FormControl(false),
    lidHarmonie: new FormControl(false),
    role: new FormControl('LID'),
    instrumentDrumband: new FormControl(''),
    instrumentJeugdorkest: new FormControl(''),
    instrumentHarmonie: new FormControl(''),
    startjaarDrumband: new FormControl(''),
    startjaarJeugdorkest: new FormControl(''),
    startjaarHarmonie: new FormControl(''),
    postcode: new FormControl(''),
    straat: new FormControl(''),
    telefoon: new FormControl(''),
    gsm: new FormControl(''),
    gemeente: new FormControl(''),
    geboorteplaats: new FormControl(''),
    geboortedatum: new FormControl(''),
    email: new FormControl('', Validators.email),
    andere: new FormControl(''),
    roles: new FormControl([])
  });

  $selectedProfiel = this.route.paramMap.pipe(
    map(params => params.get('id')),
    switchMap(id => {
      if (id === 'add') {
        this.new = true;
        return of({} as Profiel);
      }

      this.new = false;
      return this.sandbox.selectProfiel(id);
    }),
    tap(profiel => {
      if (!profiel) {
        this.router.navigate(['/']);
      }

      this.form.patchValue({...profiel});
      if (profiel?.id) {
        this.form.controls.id.disable();
      }
    })
  );

  roles = ROLE_TYPES;

  $user = this.userSandbox.$profile;

  constructor(private fb: FormBuilder, private sandbox: FirebaseSandbox, private userSandbox: UserSandbox,
              private route: ActivatedRoute, private router: Router
  ) {
  }

  save() {
    if (this.new) {
      this.sandbox.addProfiel(this.form.getRawValue())
        .then(() => this.router.navigate(['/profielen']));
    } else {
      this.sandbox.updateProfiel(this.form.getRawValue())
        .then(() => this.router.navigate(['/profielen']));
    }
  }

  delete(id: string) {
    this.sandbox.deleteProfiel(id)
      .then(() => this.router.navigate(['/profielen']));
  }

  readonly(user: Profiel, profiel: Profiel) {
    return user?.id !== profiel.id && !user?.roles?.includes(Role.writeProfiel);
  }

  noRights(user: Profiel) {
    return !user?.roles?.includes(Role.writeProfiel);
  }

  isAdmin(user: Profiel) {
    return user?.roles?.includes(Role.admin);
  }
}
