import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfielListComponent} from './profiel-list/profiel-list.component';
import {ProfielFormComponent} from './profiel-form/profiel-form.component';

const routes: Routes = [{
  path: '',
  component: ProfielListComponent
}, {
  path: ':id',
  component: ProfielFormComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfielRoutingModule {
}
