import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';

@Component({
  selector: 'app-profiel-list',
  templateUrl: './profiel-list.component.html',
  styleUrls: ['./profiel-list.component.scss'],
})
export class ProfielListComponent {

  term: string;

  $profielen = this.sandbox.$profielen;

  constructor(private sandbox: FirebaseSandbox) {
  }

  search(event: any) {
    this.term = event.detail.value;
  }
}
