import {Component} from '@angular/core';
import {GROEPEN} from '../../utils/model.utils';
import {map} from 'rxjs/operators';
import {Activiteit} from '../../models/activiteit';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {UserSandbox} from '../../sandbox/user.sandbox';
import firebase from 'firebase';
import User = firebase.User;

@Component({
  selector: 'app-activiteit-list',
  templateUrl: './activiteit-list.component.html',
  styleUrls: ['./activiteit-list.component.scss'],
})
export class ActiviteitListComponent {

  $user = this.userSandbox.$user;

  groepen = GROEPEN;

  $upcomingActiviteiten = this.sandbox.$upcomingActiviteiten;

  $upcomingActiviteitenDrumband = this.sandbox.$upcomingActiviteiten.pipe(
    map((activiteiten: Activiteit[]) => [...activiteiten].filter(e => e.groepen.includes('DRUMBAND')))
  );

  $upcomingActiviteitenHarmonie = this.sandbox.$upcomingActiviteiten.pipe(
    map((activiteiten: Activiteit[]) => [...activiteiten].filter(e => e.groepen.includes('HARMONIE')))
  );

  $upcomingActiviteitenJeugdorkest = this.sandbox.$upcomingActiviteiten.pipe(
    map((activiteiten: Activiteit[]) => [...activiteiten].filter(e => e.groepen.includes('JEUGDORKEST')))
  );

  selectedGroep: string = null;

  term: string;

  $isAdmin = this.userSandbox.$isAdmin;

  constructor(private sandbox: FirebaseSandbox, private userSandbox: UserSandbox) {
  }

  updateAanwezigheid(data: { activiteit: Activiteit; data: any }, user: User) {
    this.sandbox.setAanwezigheidActiviteit(data.activiteit, user.uid, data.data);
  }

  search(event: any) {
    this.term = event.detail.value;
  }
}
