import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import Chart from 'chart.js/auto';
import {Repetitie} from '../../models/repetitie';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment/locale/nl-be';

@Component({
  selector: 'app-aanwezigheid-chart',
  templateUrl: './aanwezigheid-chart.component.html',
  styleUrls: ['./aanwezigheid-chart.component.scss'],
})
export class AanwezigheidChartComponent implements AfterViewInit {

  @ViewChild('barChart') barChart;

  bars: any;
  colorArray: any;

  @Input() set repetities(repetities: Repetitie[]) {
    if (repetities) {
      const dateMappedRepetities = repetities.map(r => ({
        ...r,
        yearMonth: r.datetime = moment(r.datetime).locale('nl-be').format('MMMM YY')
      }));
      this.createBarChart(dateMappedRepetities);
    }
  }

  constructor() {

  }

  ngAfterViewInit() {
    // this.createBarChart();
  }

  createBarChart(groups: any[]) {
    this.bars = new Chart(this.barChart.nativeElement, {
      type: 'bar',
      data: {
        labels: _.sortedUniq(groups.map(g => g.yearMonth)),
        datasets: [{
          label: 'Aanwezig',
          data: [3, 5, 2],
          backgroundColor: 'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
          borderColor: 'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
          borderWidth: 1
        }, {
          label: 'Afwezig',
          data: [2, 1, 3],
          backgroundColor: 'red', // array should have same number of elements as number of dataset
          borderColor: 'red',// array should have same number of elements as number of dataset
          borderWidth: 1
        }, {
          label: 'Coördinator',
          data: [1, 0, 1],
          backgroundColor: 'blue', // array should have same number of elements as number of dataset
          borderColor: 'blue',// array should have same number of elements as number of dataset
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    });
  }
}
