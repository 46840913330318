import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Bestand} from '../models/bestand';
import {map, tap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {Album} from '../models/album';
import {Activiteit} from '../models/activiteit';
import {Profiel} from '../models/profiel';
import {Repetitie} from '../models/repetitie';
import {AanwezigheidRol} from '../models/aanwezigheid-type';
import {ToastController} from '@ionic/angular';
import firebase from "firebase";
import {AngularFireStorage} from "@angular/fire/storage";
import FirebaseError = firebase.FirebaseError;

@Injectable()
export class FirebaseSandbox {

  $albums: Observable<Album[]> = this.db.collection('fotos').valueChanges({idField: 'id'}).pipe(
    map((albums: Album[]) => [...albums]
      .sort((a, b) => b.date.localeCompare(a.date))
    )
  );

  $documents: Observable<Bestand[]> = this.db.collection('documenten').valueChanges({idField: 'id'}).pipe(
    map((bestanden: Bestand[]) => [...bestanden]
      .sort((a, b) => b.upload.localeCompare(a.upload))
    )
  );

  $activiteiten: Observable<Activiteit[]> = this.db.collection('activiteiten').valueChanges({idField: 'id'}).pipe(
    map((events: Activiteit[]) => [...events]
      .sort((a, b) => a.vertrek.localeCompare(b.vertrek))
    )
  );

  $upcomingActiviteiten: Observable<Activiteit[]> = this.db.collection('activiteiten').valueChanges({idField: 'id'}).pipe(
    map((events: Activiteit[]) => [...events]
      .filter(e => new Date(e.vertrek) >= new Date())
      .sort((a, b) => a.vertrek.localeCompare(b.vertrek))
    )
  );

  $upcomingRepetities: Observable<Repetitie[]> = this.db.collection('repetities').valueChanges({idField: 'id'}).pipe(
    map((repetities: Repetitie[]) => [...repetities]
      .filter(e => new Date(e.datetime) >= new Date())
      .sort((a, b) => a.datetime.localeCompare(b.datetime))
    )
  );

  $afgelopenRepetities: Observable<Repetitie[]> = this.db.collection('repetities').valueChanges({idField: 'id'}).pipe(
    map((repetities: Repetitie[]) => [...repetities]
      .filter(e => new Date(e.datetime) < new Date())
    )
  );

  $muziekstukken: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges().pipe(
    map((bestanden: Bestand[]) => [...bestanden]
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  );

  $profielen: Observable<Profiel[]> = this.db.collection('profiel').valueChanges({idField: 'id'}).pipe(
    map((profielen: Profiel[]) => [...profielen]
      .sort((a, b) => a.familienaam.localeCompare(b.familienaam))
    )
  );

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private toastController: ToastController) {

  }

  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    await toast.present();
  }

  selectProfiel(id: string): Observable<Profiel> {
    return this.db.collection('profiel').valueChanges({idField: 'id'}).pipe(
      map((profielen: Profiel[]) => profielen.find(profiel => profiel.id === id)),
      tap(profiel => {
        if (!profiel) {
          this.presentToast('Er werd geen profiel gevonden');
        }
      })
    );
  }

  addRepetitie(repetitie: Repetitie): void {
    this.db.collection('repetities').add(repetitie)
      .then(e => this.presentToast('De repetitie werd toegevoegd'))
      .catch(e => this.presentToast('De repetitie toegevoegen is mislukt'));
  }

  addActiviteit(activiteit: Activiteit): void {
    this.db.collection('activiteiten').add(activiteit)
      .then(e => this.presentToast('De activiteit werd toegevoegd'))
      .catch(e => this.presentToast('De activiteit toegevoegen is mislukt'));
  }

  setAanwezigheidRepetitie(repetitie: Repetitie, userId: string, data: AanwezigheidRol) {
    switch (data) {
      case AanwezigheidRol.aanwezig:
        this.setAanwezigRepetitie(repetitie, userId);
        break;
      case AanwezigheidRol.afwezig:
        this.setAfwezigRepetitie(repetitie, userId);
        break;
      case AanwezigheidRol.dirigent:
        this.setDirigentRepetitie(repetitie, userId);
        break;
      case AanwezigheidRol.coordinator:
        this.setCoordinatorRepetitie(repetitie, userId);
        break;
      case null:
        this.resetAanwezigheidRepetitie(repetitie, userId);
        break;
    }
  }

  setAanwezigRepetitie(repetitie: Repetitie, userId: string) {
    this.db.collection('repetities')
      .doc<Repetitie>(repetitie.id)
      .update({
        ...repetitie,
        aanwezig: [...repetitie.aanwezig.filter(a => a !== userId), userId],
        afwezig: [...repetitie.afwezig.filter(a => a !== userId)],
        coordinators: [...repetitie.coordinators.filter(a => a !== userId)],
        dirigent: repetitie.dirigent === userId ? null : repetitie?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "aanwezig"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setAfwezigRepetitie(repetitie: Repetitie, userId: string) {
    this.db.collection('repetities')
      .doc<Repetitie>(repetitie.id)
      .update({
        ...repetitie,
        aanwezig: [...repetitie.aanwezig.filter(a => a !== userId)],
        afwezig: [...repetitie.afwezig.filter(a => a !== userId), userId],
        coordinators: [...repetitie.coordinators.filter(a => a !== userId)],
        dirigent: repetitie.dirigent === userId ? null : repetitie?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "afwezig"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setCoordinatorRepetitie(repetitie: Repetitie, userId: string) {
    this.db.collection('repetities')
      .doc<Repetitie>(repetitie.id)
      .update({
        ...repetitie,
        aanwezig: [...repetitie.aanwezig.filter(a => a !== userId)],
        afwezig: [...repetitie.afwezig.filter(a => a !== userId)],
        coordinators: [...repetitie.coordinators.filter(a => a !== userId), userId],
        dirigent: repetitie.dirigent === userId ? null : repetitie?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "coördinator"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setDirigentRepetitie(repetitie: Repetitie, userId: string) {
    this.db.collection('repetities')
      .doc<Repetitie>(repetitie.id)
      .update({
        ...repetitie,
        aanwezig: [...repetitie.aanwezig.filter(a => a !== userId)],
        afwezig: [...repetitie.afwezig.filter(a => a !== userId)],
        coordinators: [...repetitie.coordinators.filter(a => a !== userId)],
        dirigent: userId
      })
      .then(e => this.presentToast('Je status werd aangepast naar "dirigent"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  resetAanwezigheidRepetitie(repetitie: Repetitie, userId: string) {
    this.db.collection('repetities')
      .doc<Repetitie>(repetitie.id)
      .update({
        ...repetitie,
        aanwezig: [...repetitie.aanwezig.filter(a => a !== userId)],
        afwezig: [...repetitie.afwezig.filter(a => a !== userId)],
        coordinators: [...repetitie.coordinators.filter(a => a !== userId)],
        dirigent: repetitie.dirigent === userId ? null : repetitie?.dirigent
      })
      .then(e => this.presentToast('Je status werd "gereset"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setAanwezigheidActiviteit(activiteit: Activiteit, userId: string, data: AanwezigheidRol) {
    console.log(activiteit, userId, data);
    switch (data) {
      case AanwezigheidRol.aanwezig:
        this.setAanwezigActiviteit(activiteit, userId);
        break;
      case AanwezigheidRol.afwezig:
        this.setAfwezigActiviteit(activiteit, userId);
        break;
      case AanwezigheidRol.dirigent:
        this.setDirigentActiviteit(activiteit, userId);
        break;
      case AanwezigheidRol.coordinator:
        this.setCoordinatorActiviteit(activiteit, userId);
        break;
      case null:
        this.resetAanwezigheidActiviteit(activiteit, userId);
        break;
    }
  }

  setAanwezigActiviteit(activiteit: Activiteit, userId: string) {
    this.db.collection('activiteiten')
      .doc<Repetitie>(activiteit.id)
      .update({
        ...activiteit,
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userId), userId],
        afwezig: [...activiteit.afwezig.filter(a => a !== userId)],
        coordinators: [...activiteit.coordinators.filter(a => a !== userId)],
        dirigent: activiteit.dirigent === userId ? null : activiteit?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "aanwezig"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setAfwezigActiviteit(activiteit: Activiteit, userId: string) {
    this.db.collection('activiteiten')
      .doc<Repetitie>(activiteit.id)
      .update({
        ...activiteit,
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userId)],
        afwezig: [...activiteit.afwezig.filter(a => a !== userId), userId],
        coordinators: [...activiteit.coordinators.filter(a => a !== userId)],
        dirigent: activiteit.dirigent === userId ? null : activiteit?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "afwezig"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setCoordinatorActiviteit(activiteit: Activiteit, userId: string) {
    this.db.collection('activiteiten')
      .doc<Repetitie>(activiteit.id)
      .update({
        ...activiteit,
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userId)],
        afwezig: [...activiteit.afwezig.filter(a => a !== userId)],
        coordinators: [...activiteit.coordinators.filter(a => a !== userId), userId],
        dirigent: activiteit.dirigent === userId ? null : activiteit?.dirigent
      })
      .then(e => this.presentToast('Je status werd aangepast naar "coördinator"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  setDirigentActiviteit(activiteit: Activiteit, userId: string) {
    this.db.collection('activiteiten')
      .doc<Repetitie>(activiteit.id)
      .update({
        ...activiteit,
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userId)],
        afwezig: [...activiteit.afwezig.filter(a => a !== userId)],
        coordinators: [...activiteit.coordinators.filter(a => a !== userId)],
        dirigent: userId
      })
      .then(e => this.presentToast('Je status werd aangepast naar "dirigent"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  resetAanwezigheidActiviteit(activiteit: Activiteit, userId: string) {
    this.db.collection('activiteiten')
      .doc<Repetitie>(activiteit.id)
      .update({
        ...activiteit,
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userId)],
        afwezig: [...activiteit.afwezig.filter(a => a !== userId)],
        coordinators: [...activiteit.coordinators.filter(a => a !== userId)],
        dirigent: activiteit.dirigent === userId ? null : activiteit?.dirigent
      })
      .then(e => this.presentToast('Je status werd "gereset"'))
      .catch(e => this.presentToast('Je status aanpassen is mislukt, probeer opnieuw...'));
  }

  selectActiviteit(id: string) {
    return this.db.collection('activiteiten').valueChanges({idField: 'id'}).pipe(
      map((activiteiten: Activiteit[]) => activiteiten.find(ac => ac.id === id))
    );
  }

  addAlbum(album: Album) {
    this.db.collection('fotos').add(album)
      .then(e => this.presentToast('Het foto album werd toegevoegd'))
      .catch(e => this.presentToast('Het foto album toegevoegen is mislukt: ' + e));
  }

  updateProfiel(profiel: Profiel) {
    return this.db.collection('profiel')
      .doc<Profiel>(profiel.id)
      .update(profiel)
      .then(e => this.presentToast('Het profiel werd gewijzigd'))
      .catch(e => this.presentToast('Het profiel wijzigen is mislukt: ' + e));
  }

  addProfiel(profiel: Profiel) {
    return this.db.collection('profiel')
      .doc<Profiel>(profiel.id)
      .set(profiel)
      .then(e => this.presentToast('Het profiel werd toegevoegd'))
      .catch(e => this.presentToast('Het profiel toevoegen is mislukt: ' + e));
  }

  deleteProfiel(id: string) {
    return this.db.collection('profiel')
      .doc<Profiel>(id)
      .delete()
      .then(e => this.presentToast('Het profiel werd verwijderd'))
      .catch(e => this.presentToast('Het profiel verwijderen is mislukt: ' + e));
  }

  addDocument(form: any, file: File) {
    const split = file.name.split('.');
    const extension = split[split.length - 1];
    const task = this.storage.upload('documenten/' + form.value.name + '.' + extension, file);

    task.then(() =>
      task.task.snapshot.ref.getDownloadURL().then(url => {
        task.task.snapshot.ref.getMetadata().then(meta => {
          this.db.collection('documenten').add({
            name: form.value.name,
            url,
            type: meta.contentType,
            size: meta.size,
            upload: new Date().toISOString(),
            extension
          } as Bestand)
            .catch((error: FirebaseError) => alert(error.message))
            .finally(() => this.presentToast('Het document werd opgeslagen.'));
        }, (error: FirebaseError) => alert(error.message));
      }, (error: FirebaseError) => alert(error.message))
    );
  }
}
