import {Component} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastController} from '@ionic/angular';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  cachedAccountKeys: any[] = [];

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private router: Router, private toastController: ToastController) {
    this.cachedAccountKeys = Object.keys(localStorage);
  }

  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 5000
    });
    await toast.present();
  }

  login() {
    this.auth
      .signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
      .then(auth => {
        window.localStorage.setItem(this.form.value.email, this.form.value.password);
        this.presentToast(`U bent in gelogd als "${this.form.value.email}"`);
        this.router.navigateByUrl('/home');
      })
      .catch(err => this.presentToast(`Inloggen mislukt: "${err}"`));
  }

  resetPassword() {
    if (this.form.value.email) {
      this.auth
        .sendPasswordResetEmail(this.form.value.email)
        .then(() => this.presentToast(('Verdere acties werden naar uw e-mailadres verstuurd om uw paswoord reset te voltooien.')))
        .catch(err => this.presentToast('Error: ' + err));
    } else {
      this.presentToast('Uw e-mailadres moet ingevuld zijn om uw wachtwoord te kunnen resetten.');
    }
  }

  logingWithKey(key: any) {
    this.form.patchValue({
      email: key,
      password: window.localStorage.getItem(key)
    });

    this.login();
  }
}
