import {Component, Input} from '@angular/core';
import {Album} from '../../models/album';

@Component({
  selector: 'app-photo-item',
  templateUrl: './photo-item.component.html',
  styleUrls: ['./photo-item.component.scss'],
})
export class PhotoItemComponent {

  @Input() album: Album;
}
