import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PhotosRoutingModule} from './photos-routing.module';
import {PhotosListComponent} from './photos-list/photos-list.component';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../shared/shared.module';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {PhotosFormComponent} from './photos-form/photos-form.component';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    PhotosListComponent,
    PhotosFormComponent
  ],
  imports: [
    CommonModule,
    PhotosRoutingModule,
    IonicModule,
    SharedModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule
  ]
})
export class PhotosModule {
}
