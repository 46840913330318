import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from '../pipes/safe.pipe';
import {IonicModule} from '@ionic/angular';
import {AanwezigheidPopoverComponent} from '../components/aanwezigheid-popover/aanwezigheid-popover.component';
import {AanwezigheidBulletComponent} from '../components/aanwezigheid-bullet/aanwezigheid-bullet.component';
import {AanwezigheidChartComponent} from '../components/aanwezigheid-chart/aanwezigheid-chart.component';
import {ContainerComponent} from '../components/container/container.component';
import {BackContainerComponent} from '../components/back-container/back-container.component';
import {PhotoItemComponent} from '../components/photo-item/photo-item.component';
import {ProfielItemComponent} from '../components/profiel-item/profiel-item.component';
import {RouterModule} from '@angular/router';
import {RepetitieItemComponent} from '../components/repetitie-item/repetitie-item.component';
import {DocumentItemComponent} from '../components/document-item/document-item.component';
import {ActiviteitItemComponent} from '../components/activiteit-item/activiteit-item.component';
import {NgBytesPipeModule} from "angular-pipes";

@NgModule({
  declarations: [
    SafePipe,
    AanwezigheidPopoverComponent,
    AanwezigheidBulletComponent,
    AanwezigheidChartComponent,
    ContainerComponent,
    BackContainerComponent,
    PhotoItemComponent,
    ProfielItemComponent,
    RepetitieItemComponent,
    DocumentItemComponent,
    ActiviteitItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    NgBytesPipeModule
  ],
  exports: [
    SafePipe,
    AanwezigheidPopoverComponent,
    AanwezigheidChartComponent,
    AanwezigheidBulletComponent,
    ContainerComponent,
    BackContainerComponent,
    PhotoItemComponent,
    ProfielItemComponent,
    RepetitieItemComponent,
    DocumentItemComponent,
    ActiviteitItemComponent
  ]
})
export class SharedModule {
}
