import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DocumentenRoutingModule} from './documenten-routing.module';
import {DocumentenPageComponent} from './documenten-page/documenten-page.component';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../shared/shared.module';
import {Ng2SearchPipeModule} from 'ng2-search-filter';

@NgModule({
  declarations: [
    DocumentenPageComponent
  ],
  imports: [
    CommonModule,
    DocumentenRoutingModule,
    IonicModule,
    SharedModule,
    Ng2SearchPipeModule
  ]
})
export class DocumentenModule {
}
