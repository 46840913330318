import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ActiviteitenRoutingModule} from './activiteiten-routing.module';
import {ActiviteitListComponent} from './activiteit-list/activiteit-list.component';
import {ActiviteitFormComponent} from './activiteit-form/activiteit-form.component';
import {ActiviteitDetailComponent} from './activiteit-detail/activiteit-detail.component';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {Ng2SearchPipeModule} from 'ng2-search-filter';


@NgModule({
  declarations: [
    ActiviteitListComponent,
    ActiviteitFormComponent,
    ActiviteitDetailComponent
  ],
  imports: [
    CommonModule,
    ActiviteitenRoutingModule,
    IonicModule,
    SharedModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule
  ]
})
export class ActiviteitenModule {
}
