import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocumentenPageComponent} from './documenten-page/documenten-page.component';
import {DocumentFormComponent} from './document-form/document-form.component';

const routes: Routes = [
  {
    path: '',
    component: DocumentenPageComponent
  },
  {
    path: 'add',
    component: DocumentFormComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentenRoutingModule {
}
