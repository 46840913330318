import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {FirebaseSandbox} from './firebase.sandbox';
import {filter, switchMap} from 'rxjs/operators';
import {Role} from "../models/role";

@Injectable()
export class UserSandbox {

  $user = this.auth.user;

  $profile = this.$user.pipe(
    switchMap(user => this.firebaseSandbox.selectProfiel(user.uid))
  );

  $isAdmin = this.$user.pipe(
    switchMap(user => this.firebaseSandbox.selectProfiel(user.uid)),
    filter(user => user.roles?.includes(Role.admin))
  );

  $isDirigent = this.$user.pipe(
    switchMap(user => this.firebaseSandbox.selectProfiel(user.uid)),
    filter(user => user.roles?.includes(Role.admin) || user.roles?.includes(Role.dirigent))
  );

  $isCoordinator = this.$user.pipe(
    switchMap(user => this.firebaseSandbox.selectProfiel(user.uid)),
    filter(user => user.roles?.includes(Role.admin) || user.roles?.includes(Role.coordinator))
  );

  constructor(private auth: AngularFireAuth, private firebaseSandbox: FirebaseSandbox) {
  }

  logout() {
    return this.auth.signOut();
  }
}
