import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToWebsite = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'repetities',
    loadChildren: () => import('./repetities/repetities.module').then(m => m.RepetitiesModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'documenten',
    loadChildren: () => import('./documenten/documenten.module').then(m => m.DocumentenModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'profielen',
    loadChildren: () => import('./profiel/profiel.module').then(m => m.ProfielModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'activiteiten',
    loadChildren: () => import('./activiteiten/activiteiten.module').then(m => m.ActiviteitenModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'aanwezigheden',
    loadChildren: () => import('./aanwezigheden/aanwezigheden.module').then(m => m.AanwezighedenModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'fotos',
    loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preload: true
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectLoggedInToWebsite,
      preload: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
