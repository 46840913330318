import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {UserSandbox} from "../../sandbox/user.sandbox";

@Component({
  selector: 'app-documenten-page',
  templateUrl: './documenten-page.component.html',
  styleUrls: ['./documenten-page.component.scss'],
})
export class DocumentenPageComponent {

  $documents = this.sandbox.$documents;
  term: string;

  $isAdmin = this.userSandbox.$isAdmin;

  constructor(private sandbox: FirebaseSandbox, private userSandbox: UserSandbox) {
  }

  search(event: any) {
    this.term = event.detail.value;
  }
}
