import {Component} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {ActivatedRoute} from '@angular/router';
import {Activiteit} from '../../models/activiteit';
import {environment} from '../../../environments/environment';
import {AanwezigheidPopoverComponent} from '../../components/aanwezigheid-popover/aanwezigheid-popover.component';
import {AanwezigheidRol} from '../../models/aanwezigheid-type';
import {PopoverController} from '@ionic/angular';
import {UserSandbox} from '../../sandbox/user.sandbox';

@Component({
  selector: 'app-activiteit-detail',
  templateUrl: './activiteit-detail.component.html',
  styleUrls: ['./activiteit-detail.component.scss'],
})
export class ActiviteitDetailComponent {

  $user = this.userSandbox.$user;

  $selectedActiviteit = this.route.paramMap.pipe(
    map(params => params.get('id')),
    switchMap(id => this.sandbox.selectActiviteit(id))
  );

  constructor(private sandbox: FirebaseSandbox, private route: ActivatedRoute,
              private popoverController: PopoverController, private userSandbox: UserSandbox) {
  }

  generateGoogleMapsUrl(event: Activiteit) {
    const noHtml = event.gemeente.replace('<u>', '').replace('</u>', '');
    return `https://www.google.com/maps/embed/v1/place?q=${event.locatie},${noHtml}&key=${environment.googleMapsToken}`;
  }

  async openAanwezigheidPopover(activiteit: Activiteit, userId: string, ev: any) {
    const popover = await this.popoverController.create({
      component: AanwezigheidPopoverComponent,
      event: ev,
      translucent: true
    });
    await popover.present();

    const {data} = await popover.onDidDismiss();
    this.sandbox.setAanwezigheidActiviteit(activiteit, userId, data);
  }

  getAanwezigheid(activiteit: Activiteit, uid: string) {
    return activiteit.aanwezig.find(a => a === uid) ? AanwezigheidRol.aanwezig :
      activiteit.afwezig.find(a => a === uid) ? AanwezigheidRol.afwezig :
        activiteit.coordinators.find(a => a === uid) ? AanwezigheidRol.coordinator :
          activiteit.dirigent === uid ? AanwezigheidRol.dirigent : null;
  }
}
