import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RepetitieListComponent} from './repetitie-list/repetitie-list.component';
import {RepetitieFormComponent} from './repetitie-form/repetitie-form.component';

const routes: Routes = [
  {
    path: '',
    component: RepetitieListComponent
  },
  {
    path: 'add',
    component: RepetitieFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RepetitiesRoutingModule {
}
