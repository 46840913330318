import {Component} from '@angular/core';
import {Bestand} from './models/bestand';
import {Howl} from 'howler';
import {FirebaseSandbox} from './sandbox/firebase.sandbox';
import {UserSandbox} from './sandbox/user.sandbox';
import {Router} from '@angular/router';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  $user = this.userSandbox.$user;

  $muziekstukken = this.sandbox.$muziekstukken;

  mediaFile: Howl;
  activeTrack: Bestand;

  public publicPages = [
    {title: 'Home', url: '/home', icon: 'home'},
    {title: 'Documenten', url: '/documenten', icon: 'book'},
    {title: 'Activiteiten', url: '/activiteiten', icon: 'musical-notes'},
    {title: 'Repetities', url: '/repetities', icon: 'musical-note'},
    {title: 'Foto\'s', url: '/fotos', icon: 'camera'},
  ];

  public adminPages = [
    {title: 'Aanwezigheden', url: '/aanwezigheden', icon: 'checkbox'},
    {title: 'Profielen', url: '/profielen', icon: 'people'},
  ];

  $isAdmin = this.userSandbox.$isAdmin;

  constructor(private userSandbox: UserSandbox, private sandbox: FirebaseSandbox, private router: Router,
              private localNotifications: LocalNotifications) {
  }

  logout() {
    this.userSandbox.logout()
      .then(() => {
        window.location.href = '/login';
      });
  }

  select(track: Bestand) {
    if (this.mediaFile) {
      this.mediaFile.stop();
    }
    this.activeTrack = track;

    this.mediaFile = new Howl({
      src: [track.url],
      html5: true
    });
    this.mediaFile.play();
  }

  pause() {
    this.mediaFile.pause();
  }

  play() {
    this.localNotifications.schedule({
      title: 'Local ILocalNotification Example',
      text: 'Multi ILocalNotification 2',
      // trigger: {at: new Date(new Date().getTime() + 60)},
    });

    this.mediaFile.play();
  }

  seek(range: any) {
    this.mediaFile.seek(range.detail.value);
  }
}
