import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PhotosListComponent} from './photos-list/photos-list.component';
import {PhotosFormComponent} from './photos-form/photos-form.component';

const routes: Routes = [
  {
    path: '',
    component: PhotosListComponent
  },
  {
    path: 'add',
    component: PhotosFormComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhotosRoutingModule {
}
