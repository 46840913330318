import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActiviteitListComponent} from './activiteit-list/activiteit-list.component';
import {ActiviteitFormComponent} from './activiteit-form/activiteit-form.component';
import {ActiviteitDetailComponent} from './activiteit-detail/activiteit-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ActiviteitListComponent
  },
  {
    path: 'add',
    component: ActiviteitFormComponent
  },
  {
    path: 'detail/:id',
    component: ActiviteitDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActiviteitenRoutingModule {
}
