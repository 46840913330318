import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfielRoutingModule} from './profiel-routing.module';
import {IonicModule} from '@ionic/angular';
import {ProfielListComponent} from './profiel-list/profiel-list.component';
import {ProfielFormComponent} from './profiel-form/profiel-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    ProfielListComponent,
    ProfielFormComponent
  ],
  imports: [
    CommonModule,
    ProfielRoutingModule,
    IonicModule,
    SharedModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule
  ]
})
export class ProfielModule {
}
