import {Component} from '@angular/core';
import {FirebaseSandbox} from '../../sandbox/firebase.sandbox';
import {UserSandbox} from "../../sandbox/user.sandbox";

@Component({
  selector: 'app-photos-list',
  templateUrl: './photos-list.component.html',
  styleUrls: ['./photos-list.component.scss'],
})
export class PhotosListComponent {

  $albums = this.firebaseSandbox.$albums;
  term: string;

  $isAdmin = this.userSandbox.$isAdmin;

  constructor(private firebaseSandbox: FirebaseSandbox, private userSandbox: UserSandbox) {
  }

  search(event: any) {
    this.term = event.detail.value;
  }
}
